.site-card-border-less-wrapper {
    padding: 30px;
    background: #ececec;
    margin-top: 20%;
}

.loginImg{
    padding: 20px;
}

body{
    background: #ececec;
}

.cardLogin{
    border-radius: 10px;
    box-shadow: 0px 8px 20px #00000033;
    width: 350px;
    margin-top: 10%;
}